<template>
    <div>
        Error Page
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import RegisterStoreModule from 'mixins/RegisterStoreModule'
export default {
    name: 'OGTagExp',
    mixins: [RegisterStoreModule],
    created() {
        this.fetchOGTagEHDDetails(this.$route.params.id).then(() => {
            console.log(this.ogTagDetails)
            document.querySelector("meta[property='og:title']").content = this.ogTagDetails.ehd_title
            document.querySelector("meta[property='og:description']").content = this.ogTagDetails.ehd_description
            document.querySelector("meta[property='og:image']").content = this.ogTagDetails.ehd_image
        })
        console.log('created')
    },
    metaInfo: {
        title: 'SILOHO',
        titleTemplate: '%s | EHD Page',
        bodyAttrs: {
            class: ['ehd-page-body'],
        },
    },
    computed: {
        ...mapState({
            ogTagDetails: state => state.MetaTagModule.ogTagDetails,
        }),
    },
    methods: {
        ...mapActions({
            fetchOGTagEHDDetails: 'fetchOGTagEHDDetails',
        }),
    },
}
</script>

<style lang="scss" scoped></style>
